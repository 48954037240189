import { useEffect, useMemo, useState } from 'react'
import { ApolloProvider } from '@apollo/client'
import { GetStaticProps } from 'next'
import { I18n, i18n } from 'next-i18next'
import { useRouter } from 'next/router'
import { I18nextProvider } from 'react-i18next'
import locales from '@/constants/locales'
import { ThemeContextProvider } from '@/contexts/ThemeContext'
import { Analytics } from '@/layout/Analytics'
import { NextPageWithLayout } from '@/layouts'
import { LanguagesProvider } from '@/molecules/LanguageSelector'
import { ToastProvider } from '@/molecules/Toast/ToastProvider'
import { Footer } from '@/organisms/Footer'
import { SiteNav } from '@/organisms/SiteNav'
import { getWebClient } from '@/services/ApolloClient'
import { getLocaleFromParams } from '@/utils/LocaleUtil'
import { getLocaleFromPath } from '@/utils/LocaleUtil/LocaleUtil'
import { getStaticPropsErrorHandler, getUrlFromFilePath } from '@/utils/nextUtils/nextErrorHandlers'
import { loadTranslations } from '@/utils/translate/translate-server'
import { NotFoundView } from '@/views/NotFoundView'

const NotFound: NextPageWithLayout = () => {
  const [isMounted, setMounted] = useState(false)
  const { asPath } = useRouter()

  const locale = useMemo(() => {
    return getLocaleFromPath(asPath)
  }, [asPath])

  const client = getWebClient({ locale })

  useEffect(() => {
    if (!isMounted) {
      setMounted(true)
    }

    if (i18n?.language !== locale) {
      i18n?.changeLanguage(locale)
    }
  }, [isMounted, locale])

  return isMounted ? (
    <I18nextProvider i18n={i18n as I18n}>
      <LanguagesProvider>
        <Analytics>
          <ApolloProvider client={client}>
            <ThemeContextProvider isDarkMode>
              <ToastProvider>
                <SiteNav />
                <NotFoundView />
                <Footer />
              </ToastProvider>
            </ThemeContextProvider>
          </ApolloProvider>
        </Analytics>
      </LanguagesProvider>
    </I18nextProvider>
  ) : (
    <div className="bg-950 fixed inset-0" />
  )
}

export const getStaticProps: GetStaticProps = async ({ params }) =>
  getStaticPropsErrorHandler({ resolvedUrl: getUrlFromFilePath('pages/404.tsx', params) }, async () => {
    const locale = getLocaleFromParams(params)
    return {
      props: {
        ...(await loadTranslations(locale, ['common', 'home', 'account'], locales.all)),
      },
    }
  })

NotFound.getLayout = (page) => page

export default NotFound
